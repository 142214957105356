<template lang="html">

  <section class="banner-links d-flex  aife" :banner="titleBanner" :id="this.id">
    <div class="container column-center container-main-link">
      <h2 class="title-line -white" v-if="titleBanner == 'desarrollo-personas'">DESARROLLO DE PERSONAS UFV</h2>

      <h2 class="title-line -white" v-if="titleBanner == 'accesos-directos'">Accesos directos</h2>


      <agile :options="myOptions" ref="carousel" class="d-flex jcsb aife container-links container w-100"
        v-if="Object.keys(dataBanner).length != 0">
        <template v-for="(banner, key ) in dataBanner" :key="key">
          <template v-if="banner.departments && banner.departments.length <= 1">
            <template v-for="(d, key ) in banner.departments" :key="key">
              <router-link class="link-box" :to="{
                name: targetPage,
                params: {
                  slug: d.slug,
                  slugGestion: banner.slug
                }
              }" @mousemove="manual(banner.slug)" @mouseleave="reanudar()">
                {{ banner.name }}

                <p class="description" v-if="banner.description">
                  {{ banner.description }}
                </p>
                <p class="description" v-if="banner.header">
                  {{ banner.header }}
                </p>
              </router-link>

            </template>

          </template>
          <template v-else>
            <router-link class="link-box" :to="{
              name: 'gestion-page',
              params: {
                slug: banner.slug,
                name: banner.name
              }
            }" @mousemove="manual(banner.slug)" @mouseout="reanudar()">
              {{ banner.name }}


              <p class="description" v-if="banner.description">
                {{ banner.description }}
              </p>
              <p class="description" v-if="banner.header">
                {{ banner.header }}
              </p>
            </router-link>

          </template>
        </template>

      </agile>
    </div>
    <button @click="$refs.carousel.goToNext()" class="nextSlide" title="Next Slide"></button>
    <button @click="$refs.carousel.goToPrev()" class="prevSlide" title="Prev Slide"></button>
  </section>

</template>

<script lang="js">
import { contentStore } from '@/stores/contents'
import {
  VueAgile
} from "vue-agile";
export default {
  name: 'banner-links',
  components: {
    agile: VueAgile,
  },
  props: ['titleBanner', 'dataBanner', 'targetPage'],
  mounted() {


  },
  created() {
    if (this.timerId == false) {
      this.timerId = setInterval(() => {

        var ids = new Array;
        for (var x = 0; x < Object.keys(this.dataBanner).length; x++) {
          ids.push(Object.keys(this.dataBanner)[x]);
        }
        this.id = ids[this.index];
        this.index++;
        if (this.index == Object.keys(this.dataBanner).length) {
          this.index = 0;
        }
      }, 5000)
    }

  },
  setup() {
    const store = contentStore();
    return {
      store
    }
  },

  data: () => ({
    id: null,
    timerId: false,
    index: 1,
    myOptions: {
      responsive: [{
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          dots: false,
          navButtons: false,
          centerMode: true,
          unagile: false,
        },
      }, {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          dots: true,
          navButtons: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          dots: true,
          navButtons: false,

        },
      }, {
        breakpoint: 0,
        settings: {
          dots: true,
          slidesToShow: 1,
          navButtons: false,

        },
      },
      ],
    },
  }),
  methods: {
    manual(x) {
      this.id = x;
      clearInterval(this.timerId);
      this.timerId = true;
    },
    reanudar() {

      this.timerId = false;
      if (this.timerId == false) {
        this.timerId = setInterval(() => {
          var ids = new Array;
          for (var x = 0; x < Object.keys(this.dataBanner).length; x++) {
            ids.push(Object.keys(this.dataBanner)[x]);
          }
          this.id = ids[this.index];
          this.index++;
          if (this.index == Object.keys(this.dataBanner).length) {
            this.index = 0;
          }
        }, 5000)
      }
    }



  },
  computed: {

  }
}


</script>

<style scoped lang="scss">
.banner-links {}
</style>