<template lang="html">
    <section class="entries-page">

        <div class="container">
            <div class="box-filters">
                <p class="title-line">Avisos</p>
                <div class="filters">
                    <select v-model="categorySelected" @change="filterCategory" name="categories" id="categories"
                        v-if="Object.keys(store.categoriesEntries).length != 0">
                        <option :value="null">{{ cleanTextTheme }}</option>
                        <template v-for="(cat, key ) in store.categoriesEntries" :key="key">
                            <option v-if="cat.id != '1' && cat.id != '2' && cat.id != '3'" :value="cat.id">{{ cat.name }}
                            </option>
                        </template>
                    </select>
                    <div class="box-tool-search">
                        <label for="searchTool" style="display:none">Buscar</label>
                        <input class="input-styled" type="text" name="buscar" placeholder="Buscar" id="searchTool"
                            v-model="textSearch" />

                        <button class="btn-search" @click="searchText" title="Buscar"></button>
                        <button v-if="removeSearch" class="btn-clean" title="Limpiar búsqueda" @click="cleanText"></button>
                    </div>
                </div>
            </div>
          
        
            <div class="grid-4" v-if="store.getEntries(this.page)">
                <div class="grid" v-for="(entryData, key ) in store.getEntries(this.page)" :key="key">
                    <entryView :dataEntry="entryData"></entryView>
                </div>
            </div>
            <template v-else>
                <div class="container container-result">
                    <p class="title-line -center">No hay resultados</p>
                </div>
            </template>

        </div>
        <paginator v-if="store.getEntries(this.page)" :pageActual="page" :totalItems="NewsMaxItems" @clicked="changePages">
        </paginator>
    </section>
</template>
  
<script lang="js">
import entryView from '@/components/entry-view.vue'
import paginator from '@/components/paginator.vue'
import { contentStore } from '@/stores/contents'
const store = contentStore();
export default {
    name: 'entries-page',
    props: [],
    components: {
        entryView,
        paginator
    },
    setup() {
        return {
            store,
            getLightEntryByCategoryBySLug: store.getLightEntryByCategoryBySLug,
            setPageEntries: store.setPageEntries,
            getEntriesPage: store.getEntriesPage
        }
    },
    mounted() {
        this.categorySelected = store.entries.lastFilters.filter;
        this.textSearch = store.entries.lastFilters.text,
            store.loadLightEntries()
        store.loadEntries(
            {
                page: this.page,

            }
        )
        store.loadCategoriesEntries()

    },
    data() {
        return {
            textSearch: null,
            removeSearch: false,
            categorySelected: null,
        }
    },
    methods: {
        searchText() {
            store.loadEntries(
                {
                    page: this.page,
                    text: this.textSearch
                }
            )
            this.removeSearch = true

        },
        cleanText() {
            this.textSearch = null,
                this.removeSearch = false
            store.loadEntries(
                {
                    page: this.page,
                    text: this.textSearch
                }
            )

        },
        filterCategory() {
            store.loadEntries(
                {
                    page: this.page,
                    filter: this.categorySelected
                }
            )
        },
        changePages(item) {
            this.setPageEntries(item)
            store.loadEntries(
                {
                    page: this.page,
                }
            )
        }
    },
    computed: {
        cleanTextTheme() {
            if (this.categorySelected != null) {
                return 'Eliminar selección'
            } else {
                return 'Ver todos'
            }
        },
        NewsMaxItems() {
            return store.getPagesEntries();
        },
        page() {
            return store.getEntriesPage
        }
    },
    watch: {
        'textSearch'() {
            if (this.textSearch == '') {
                store.loadEntries(
                    {
                        page: this.page,
                    }
                )
            }
        }
    }

}


</script>
  
<style scoped lang="scss">
.entries-page {
    margin-top: 50px
}
</style>