<template lang="html">

  <section class="notice-event-view" v-if="dataEntry">
    <router-link :to="{
        name : 'view-entry-new-event',
        params: {
        slug:dataEntry.slug,
      }}" class="box-entry" :class="[dataEntry.type == 1 ? 'event-entry' : '']"
      :style="`background-image:url(` + dataEntry.image.url + `)`">
      <section class="content-info">
        <p class="title">{{dataEntry.name}}</p>
        <p class="date" v-if="dataEntry.type == 1">{{currentDateTime(dataEntry.start_date)}}</p>
        <p class="date" v-else>{{currentDateTime(dataEntry.publish_date)}}</p>

        <p class="categories">
          <span class="uppercase">{{dataEntry.category.name}}</span>
        </p>
        <p class="description">{{dataEntry.header}}</p>
      </section>
    </router-link>
  </section>

</template>

<script lang="js">
  import moment from 'moment'
  export default {
    name: 'notice-event-view',
    props: ['dataEntry'],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      currentDateTime(date) {
        return moment(date).locale('es').format('L')
      },
    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .notice-event-view {}
</style>