<template lang="html">

  <section class="paginator ">
    <div class="container">
      <div class="container-buttons">
        <button class="prev-page" title="Ir a la página anterior" @click="goPrev" v-show="this.pageActual > 1"></button>
        <template v-for="(item, key) in totalItems" :key="key">
          <button @click="gotopage(item)" :class="[pageActual == item ? 'page-item active' : 'page-item']"
            :title="'Ir a la página ' + item">
            {{ item }}
          </button>
        </template>
        <button class="next-page" title="Ir a la página siguiente" @click="goNext"
          v-show="this.pageActual < this.totalItems"></button>
      </div>
    </div>

  </section>

</template>

<script lang="js">

  export default {
    name: 'paginator',
    props: ['pageActual', 'totalItems'],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      gotopage(item) {
        this.$emit('clicked', item);
      },
      goNext() {

        this.$emit('clicked', this.pageActual + 1);
      },
      goPrev() {
        this.$emit('clicked', this.pageActual - 1);
      }
    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .paginator {}
</style>